import React from 'react';

import { ReactComponent as Checkmark } from '@/globals/images/checkmark.svg';
import pictureFrame from '@/globals/images/pictureFrame.svg';
import infoIcon from '@/globals/images/infoIcon.svg';
import PicturePreparationImage from '@/globals/images/hero_picturePreparationStep.jpg';

import Button from '@/components/Button';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Title from '@/components/Title';

import { shouldRefresh } from '@/helpers/refresh';

import Styles from './styles.module.scss';
import { TPicturePreparationStep } from './interfaces';

const PicturePreparationStep: React.FC<TPicturePreparationStep> = ({ onSubmit }) => {
  React.useEffect(() => {
    if (shouldRefresh()) {
      window.location.reload();
    }
  }, []);

  return (
    <div className={Styles.picturePreparationStep}>
      <div className={Styles.picturePreparationStep__visual}>
        <img className={Styles.picturePreparationStep__visual__image} src={PicturePreparationImage} alt='' />
        <img className={Styles.picturePreparationStep__pictureFrame} src={pictureFrame} alt='' />
      </div>
      <Inner className={Styles.picturePreparationStep__content}>
        <div>
          <Title type='h2' className={Styles.picturePreparationStep__title}>
            <LocaleText>page.picturePreparationStep.title</LocaleText>
          </Title>
          <div className={Styles.picturePreparationStep__warning}>
            <img src={infoIcon} alt='' />
            <LocaleText className={Styles.picturePreparationStep__warningText}>page.picturePreparationStep.warning</LocaleText>
          </div>
          <div className={Styles.picturePreparationStep__description}>
            <LocaleText isHtml>page.picturePreparationStep.description</LocaleText>
            <div className={Styles.picturePreparationStep__tipSection}>
              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText>page.picturePreparationStep.firstTip</LocaleText>
              </div>

              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText isHtml>page.picturePreparationStep.secondTip</LocaleText>
              </div>

              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText>page.picturePreparationStep.thirdTip</LocaleText>
              </div>

              <div className={Styles.picturePreparationStep__tip}>
                <Checkmark className={Styles.picturePreparationStep__tipIcon} />
                <LocaleText>page.picturePreparationStep.forthTip</LocaleText>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.picturePreparationStep__dataPrivacy}>
          <div className={Styles.picturePreparationStep__dataPrivacy__title}>
            <LocaleText>page.picturePreparationStep.dataStatementTitle</LocaleText>
          </div>
          <div className={Styles.picturePreparationStep__dataPrivacy__text}>
            <LocaleText>page.picturePreparationStep.dataStatementText</LocaleText>
          </div>
        </div>
        <Button onClick={onSubmit} className={Styles.picturePreparationStep__button} rounded>
          <LocaleText>page.picturePreparationStep.submit</LocaleText>
        </Button>
      </Inner>
    </div>
  );
};

export default PicturePreparationStep;
