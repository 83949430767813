import { createRoot } from "react-dom/client";

import "./globals/styles/index.scss";

import reportWebVitals from "./reportWebVitals";

import AppRouter from "./routers/AppRouter";

if (process.env.NODE_ENV === "development") {
  const root = createRoot(document.getElementById("sa-diagnosis")!);
  root.render(<AppRouter />);
} else {
  const container = document.getElementById("sa-diagnosis");
  if (container && !container.shadowRoot) {
    const shadowRoot = container.attachShadow({ mode: "open" });
    const root = createRoot(shadowRoot);

    const styles = document.createElement("link");
    styles.setAttribute("rel", "stylesheet");
    styles.setAttribute(
      "href",
      `${process.env.REACT_APP_PUBLIC_PATH}sa_styles.css`
    );
    shadowRoot.appendChild(styles);

    root.render(<AppRouter />);
  }
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
