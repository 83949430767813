import { adjustColorBrightness } from "@/helpers/color";
import { useGenerateECommerce } from "@/hooks/useGenerateECommerce";
import { FC, ReactNode, createContext, useEffect, useMemo } from "react";
import { TECommerceInfo } from "@/helpers/API/requests/interfaces/customer";

interface ECommerceProviderProps {
  children: ReactNode;
}

type ECommerceContextType = TECommerceInfo & {
  token: string;
  eCommerceLoading: boolean;
  eCommerceError: string | null;
};

export const ECommerceContext = createContext<ECommerceContextType>({
  token: "",
  color1: "",
  color2: "",
  title: "",
  subtitle: "",
  description: "",
  name: "",
  availablePathologies: [],
  hideSensitiveSkin: false,
  hideEmailRequest: true,
  cguLink: "",
  externalCartUrl: "",
  type: "Custom",
  eCommerceLoading: false,
  eCommerceError: null,
  customer: null,
});

export const ECommerceProvider: FC<ECommerceProviderProps> = ({ children }) => {
  const { eCommerceData, isLoading, token, eCommerceError } =
    useGenerateECommerce();

  const eCommerceInfos = useMemo(() => {
    if (eCommerceData) {
      const {
        e_commerce: {
          color1,
          color2,
          title,
          subtitle,
          description,
          name,
          availablePathologies,
          hideSensitiveSkin,
          hideEmailRequest,
          cguLink,
          externalCartUrl,
          type,
          customer,
        },
      } = eCommerceData;
      return {
        color1,
        color2,
        title,
        subtitle,
        description,
        name,
        availablePathologies,
        hideSensitiveSkin,
        hideEmailRequest,
        cguLink,
        externalCartUrl,
        type,
        customer,
      };
    }
    return {
      color1: "",
      color2: "",
      title: "",
      subtitle: "",
      description: "",
      name: "",
      availablePathologies: [],
      hideSensitiveSkin: false,
      hideEmailRequest: true,
      cguLink: "",
      externalCartUrl: "",
      type: "Custom" as ECommerceContextType["type"],
      customer: null,
    };
  }, [eCommerceData]);

  useEffect(() => {
    if (eCommerceInfos.color1 !== "") {
      document.documentElement.style.setProperty(
        "--color-sa-action",
        eCommerceInfos.color1
      );
      document.documentElement.style.setProperty(
        "--color-sa-action-translucent",
        `${eCommerceInfos.color1}80`
      );
      document.documentElement.style.setProperty(
        "--color-sa-action-dark",
        adjustColorBrightness(eCommerceInfos.color1, -10)
      );
      document.documentElement.style.setProperty(
        "--color-sa-action-light",
        `${eCommerceInfos.color1}1A`
      );
    }
    if (eCommerceInfos.color2 !== "") {
      document.documentElement.style.setProperty(
        "--color-sa-info",
        eCommerceInfos.color2
      );
    }
  }, [eCommerceInfos]);

  return (
    <ECommerceContext.Provider
      value={{
        token,
        ...eCommerceInfos,
        eCommerceLoading: isLoading,
        eCommerceError,
      }}
    >
      {children}
    </ECommerceContext.Provider>
  );
};
