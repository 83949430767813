import { TAddProductGressillonsCart } from "./interface";

export const addProductToGressillonsCart: TAddProductGressillonsCart = (
  cart,
  cartUrl
) => {
  const groupedItems = cart.reduce<Record<string, number>>((acc, product) => {
    if (acc[product.externalId]) {
      acc[product.externalId] += 1;
    } else {
      acc[product.externalId] = 1;
    }
    return acc;
  }, {});

  const externalIds = Object.entries(groupedItems)
    .map(([externalId, quantity]) => Array(quantity).fill(externalId))
    .flat();

  const addToCartUrl = `${cartUrl}?add-to-cart=${externalIds.join(",")}`;

  window.location.href = addToCartUrl;
};
