import React from 'react';

import { Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';

import jwt_decode from 'jwt-decode';

import ErrorBoundary from '@/ErrorBoundary';

import AuthProvider, { AuthConsumer, TAuthRetrieveUserCallback } from '@/providers/AuthProvider';
import LocaleProvider from '@/providers/LocaleProvider';

/**
 * Routers
 */
import NotAuthRouter from '../NotAuthRouter';
import AuthRouter from '../AuthRouter';

import translations from '@/translations';

import { initRefresh } from '@/helpers/refresh';
import { ECommerceProvider } from '@/providers/AuthProvider/eCommerceInfoProvider';

/**
 * Get router's history
 */
export const history = createMemoryHistory();

/**
 * @param token stored on cookie to allow re-authentification.
 * @returns User's information stored on authentification.
 */
const onRetrieveUserFromCookie: TAuthRetrieveUserCallback = (token) => Promise.resolve(jwt_decode(token));

const AppRouter: React.FC = () => {
  const [{ action, location }, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useLayoutEffect(() => history.listen(setState), [history]);

  React.useEffect(() => {
    initRefresh();
  }, []);

  return (
    <Router location={location} navigationType={action} navigator={history}>
      <ECommerceProvider>
        <LocaleProvider translations={translations}>
          <AuthProvider onRetrieveUserFromCookie={onRetrieveUserFromCookie}>
            <ErrorBoundary>
              <AuthConsumer>{({ user }) => (user === null ? <NotAuthRouter /> : <AuthRouter />)}</AuthConsumer>
            </ErrorBoundary>
          </AuthProvider>
        </LocaleProvider>
      </ECommerceProvider>
    </Router>
  );
};

export default AppRouter;
