import { TAddProductToShopifyCart } from "./interface";

export const addProductToShopifyCart: TAddProductToShopifyCart = async (
  cart,
  cartUrl
) => {
  const groupedItems = cart.reduce<Record<string, number>>((acc, product) => {
    if (acc[product.externalId]) {
      acc[product.externalId] += 1;
    } else {
      acc[product.externalId] = 1;
    }
    return acc;
  }, {});

  const items = Object.entries(groupedItems).map(([externalId, quantity]) => ({
    id: externalId,
    quantity,
  }));

  const formData = { items };

  try {
    const response = await fetch(`${cartUrl}/add.js`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return true;
    } else {
      throw new Error("Erreur lors de l'ajout au panier");
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
