export enum ECustomerGender {
  /**
   * The customer is a woman
   */
  WOMAN = "woman",

  /**
   * The customer is a man
   */
  MAN = "man",
}

export enum ECustomerAge {
  /**
   * The customer is an adolescent (between 16 and 24 year old)
   */
  ADO = "16-24",

  /**
   * The customer is a young adult (between 25 and 34 year old)
   */
  YOUNG_ADULT = "25-34",

  /**
   * The customer is a senior adult (between 35 and 54 year old)
   */
  SENIOR_ADULT = "35-54",

  /**
   * The customer is an elder (between 55 and 79 year old)
   */
  ELDER = "55-79",
}

export enum ECustomerSkin {
  /**
   * The customer has a normal skin
   */
  NORMAL = "normale",

  /**
   * The customer has a dry skin
   */
  DRY = "seche",

  /**
   * The customer has an oily skin
   */
  OILY = "grasse",

  /**
   * The customer has a combination skin
   */
  COMBINATION = "mixte",
}

export enum ECustomerPathology {
  /**
   * The customer has no pathology.
   */
  NULL = "none",

  /**
   * The customer has eczema.
   */
  ECZEMA = "eczema",

  /**
   * The customer has acne.
   */
  ACNE = "acne",

  /**
   * The customer has psoriasis
   */
  PSORIASIS = "psoriasis",

  /**
   * The customer has dermatite sebborheique
   */
  DERMATITE_SEBBORHEIQUE = "dermatite_sebborheique",

  /**
   * The customer has couperose
   */
  ROSACE_COUPEROSE = "rosace_couperose",
}

export enum ECustomerSkinProblem {
  /**
   * The customer's main problem to solve is hydration
   */
  HYDRATION = "hydratation",

  /**
   * The customer's main problem to solve is wrinkles
   */
  WRINKLES = "rides",

  /**
   * The customer's main problem to solve is firmness
   */
  FIRMNESS = "fermete",

  /**
   * The customer's main problem to solve is pores
   */
  PORES = "pores",

  /**
   * The customer's main problem to solve is brightness
   */
  BRIGHTNESS = "teint_eclat",

  /**
   * The customer's main problem to solve is stain
   */
  STAIN = "tache",
}

export enum ECustomerSkinProblemSlug {
  /**
   * The customer's main problem to solve is hydration
   */
  HYDRATION = "Hydration",

  /**
   * The customer's main problem to solve is wrinkles
   */
  WRINKLES = "wrinkles",

  /**
   * The customer's main problem to solve is acne
   */
  ACNE = "acne",

  /**
   * The customer's main problem to solve is firmness
   */
  FIRMNESS = "sagging",

  /**
   * The customer's main problem to solve is pores
   */
  PORES = "pores",

  /**
   * The customer's main problem to solve is brightness
   */
  BRIGHTNESS = "translucency",

  /**
   * The customer's main problem to solve is stain
   */
  STAIN = "pigmentation",

  /**
   * The customer's main problem to solve is eye's outline
   */
  EYE_OUTLINE = "eye_bags",
}

export type TCustomerSelfieBody = {
  /**
   * Selfie picture sent as base 64.
   */
  base64: string;
};

export type TScoreData = {
  description: string;
  front_name: string;
  masks: TCustomerMasks;
  name: string;
  score: number;
  score_type: string;
  slug: string;
};

export type TCustomerSelfieResponse = {
  /**
   * Customer's selfie analysis results
   */
  results: TScoreData[];
};

export type TCustomerAnswerBody = TCustomerSelfieResponse & {
  /**
   * Customer's email.
   */
  customer_email: string;

  /**
   * Customer's gender.
   */
  gender: ECustomerGender;

  /**
   * Customer's age range.
   */
  age: ECustomerAge;

  /**
   * Customer's pathology.
   */
  pathology: ECustomerPathology;

  /**
   * Customer's skin type.
   */
  skin: ECustomerSkin;

  /**
   * Is customer's skin sensitive ?
   */
  sensitive_skin: boolean;

  /**
   * Customer's problem
   */
  problem: ECustomerSkinProblem;

  /**
   * Customer want to test eye's outline.
   */
  eye_area: boolean;

  /**
   * Check if user is on mobile device
   */
  is_mobile: boolean;
};

export type TCustomerProposedProductBrand = {
  /**
   * Brand's id.
   */
  id: number;

  /**
   * Brand's name.
   */
  name: string;
};

export type TCustomerProposedProduct = {
  /**
   * Customer proposed product's id.
   */
  id: number;

  /**
   * Customer proposed product's European Article Numbering.
   */
  ean: string;

  /**
   * Customer proposed product's external id (from eCommerce website).
   */
  externalId: string;

  /**
   * Customer proposed product's name.
   */
  name: string;

  /**
   * Customer proposed product's description.
   */
  description: string;

  /**
   * Is customer proposed product bought.
   */
  is_bought: boolean;

  /**
   * Customer proposed product image's url.
   */
  image_url: string;

  /**
   * Customer proposed product's brand.
   */
  brand: TCustomerProposedProductBrand;
};

export type TCustomerProposedProducts = TCustomerProposedProduct[];

export type TCustomerRoutine = {
  /**
   * Customer routine's title.
   */
  title: string;

  /**
   * Customer routine's position.
   */
  position: number;

  /**
   * Customer routine's proposed_products.
   */
  proposed_products: TCustomerProposedProducts;

  /**
   * Customer routine's subRoutine.
   */
  sub_routines: TCustomerRoutines;
};

export type TCustomerRoutines = TCustomerRoutine[];

export enum ECustomerMaskFeatureGeometryType {
  /**
   * Customer mask feature geometry type is a multi polygon.
   */
  MultiPolygon = "MultiPolygon",

  /**
   * Customer mask feature geometry type is a multi point.
   */
  MultiPoint = "MultiPoint",

  /**
   * Customer mask feature geometry type is a multi lines.
   */
  MultiLineString = "MultiLineString",
}

/**
 * Multi polygon's points are characterized by x and y
 */
export type TMultiPolygonCoordinatePoint = [number, number];

export type TCustomerMaskFeatureGeometryMultiPolygonCoordinate =
  TMultiPolygonCoordinatePoint[][];

export type TCustomerMaskFeatureGeometryMultiPolygonCoordinates =
  TCustomerMaskFeatureGeometryMultiPolygonCoordinate[];

/**
 * Multi points' points are characterized by x, y and radius
 */
export type TMultiPointCoordinatePoint = [number, number, number];

export type TCustomerMaskFeatureGeometryMultiPointsCoordinate =
  TMultiPointCoordinatePoint;

export type TCustomerMaskFeatureGeometryMultiPointsCoordinates =
  TCustomerMaskFeatureGeometryMultiPointsCoordinate[];

/**
 * Multi lines' points are characterized by x, y
 */
export type TMultiLinesCoordinatePoint = [number, number];

export type TCustomerMaskFeatureGeometryMultiLinesCoordinate = [
  TMultiLinesCoordinatePoint,
  TMultiLinesCoordinatePoint
];

export type TCustomerMaskFeatureGeometryMultiLinesCoordinates =
  TCustomerMaskFeatureGeometryMultiLinesCoordinate[];

export type TCustomerMaskFeatureGeometry = {
  /**
   * Customer mask feature geometry's type.
   */
  type: ECustomerMaskFeatureGeometryType;

  /**
   * Customer mask feature geometry's coordinates.
   */
  coordinates:
    | TCustomerMaskFeatureGeometryMultiPolygonCoordinates
    | TCustomerMaskFeatureGeometryMultiPointsCoordinates
    | TCustomerMaskFeatureGeometryMultiLinesCoordinates;
};

export type TCustomerMaskFeatureProperties = {
  /**
   * Customer mask feature properties's intensity.
   */
  intensity: number;
};

export type TCustomerMaskFeature = {
  /**
   * Customer mask feature's geometry.
   */
  geometry: TCustomerMaskFeatureGeometry;

  /**
   * Customer mask feature's geometry.
   */
  properties: TCustomerMaskFeatureProperties;
};

export type TCustomerMaskFeatures = TCustomerMaskFeature[];

export enum ECustomerMaskType {
  /**
   * Customer mask type is a polygon.
   */
  polygon_mask = "polygon_mask",

  /**
   * Customer mask type is a point.
   */
  point_mask = "point_mask",

  /**
   * Customer mask type is a polyline.
   */
  polyline_mask = "polyline_mask",

  /**
   * Customer mask type is a heatmap.
   */
  heatmap_mask = "heatmap_mask",
}

export type TCustomerMask = {
  /**
   * Customer mask's type
   */
  mask_type: ECustomerMaskType;

  /**
   * Customer mask's features
   */
  features: TCustomerMaskFeatures;

  /**
   * Customer mask's view box dimension.
   */
  view_box: string;

  /**
   * Customer mask's fill color.
   */
  fill: string;

  /**
   * Customer mask's name
   */
  name: string;

  /**
   * Customer mask technology's name
   */
  tech_name: string;

  /**
   * Customer mask's type
   */
  type: string;
};

export type TCustomerMasks = TCustomerMask[];

export type TCustomerCategory = {
  /**
   * Customer category's name.
   */
  name: string;

  /**
   * Customer category's score.
   */
  score: number;

  /**
   * Customer category's description.
   */
  description: string;

  /**
   * Customer category's description.
   */
  masks: TCustomerMasks;
};

export type TCustomerCategories = TCustomerCategory[];

export type TCustomerAnswerResponse = {
  /**
   * Customer cart's id.
   */
  cart_id: number;

  /**
   * Customer routines.
   */
  routines: TCustomerRoutines;

  /**
   * Customer areas' categories.
   */
  categories: TCustomerCategories;
};

export type TCustomerCartValidationBody = {
  /**
   * Checked product's ids
   */
  checked_products: Array<number>;
};

export type TCustomerCartValidationResponse = {
  /**
   * Checked product's ids
   */
  success: boolean;
};

export type TCustomerSendRecapItems = {
  /**
   * Item's title
   */
  title: ECustomerSkinProblemSlug;

  /**
   * Item's score
   */
  score: string | number;
};

export type TCustomerSendRecapBody = {
  /**
   * Customer's Email
   */
  email: string;

  /**
   * Customer's array of items and scores
   */
  items: Array<TCustomerSendRecapItems>;
};

export type TCustomerSendRecapResponse = {
  /**
   * Response, success or failure
   */
  success: boolean;
};

export type TECommerceInfo = {
  /**
   * The first color associated with the eCommerce.
   */
  color1: string;

  /**
   * The second color associated with the eCommerce.
   */
  color2: string;

  /**
   * The title related to the eCommerce.
   */
  title: string;

  /**
   * The subtitle related to the eCommerce.
   */
  subtitle: string;

  /**
   * The description of the eCommerce.
   */
  description: string;

  /**
   * The name of the eCommerce
   */
  name: string;

  /**
   * List the pathologies checkbox to show in the PathologyForm.
   */
  availablePathologies: Array<string>;

  /**
   * Skip the SensibilityForm if true.
   */
  hideSensitiveSkin: boolean;

  /**
   * Skip the user email request if true.
   */
  hideEmailRequest: boolean;

  /**
   * Link to the terms and conditions of the eCommerce
   */
  cguLink: string;

  /**
   * Link to be used to redirect the user to their shopping cart at the end of the process
   */
  externalCartUrl: string;

  /**
   * Type of eCommerce
   */
  type: "Custom" | "Shopify" | "Woocommerce";

  /**
   * Customer for custom eCommerce
   */
  customer: "gresillons" | null;
};

export type TGenerateECommerceResponse = {
  /**
   * The JWT token.
   */
  token: string;

  /**
   * Information related to the eCommerce.
   */
  e_commerce: TECommerceInfo;
};

export type TCustomerSelfie = (
  body: TCustomerSelfieBody,
  locale: String
) => Promise<TCustomerSelfieResponse>;

export type TCustomerAnswer = (
  body: TCustomerAnswerBody,
  locale: string,
  campaign?: string
) => Promise<TCustomerAnswerResponse>;

export type TCustomerCartValidation = (
  id: number,
  body: TCustomerCartValidationBody
) => Promise<TCustomerCartValidationResponse>;

export type TCustomerSendRecap = (
  body: TCustomerSendRecapBody
) => Promise<TCustomerSendRecapResponse>;
