import { addProductToGressillonsCart } from "./gresillons";
import { THandleAddProductToCart, TSendToExternalCart } from "./interface";
import { addProductToShopifyCart } from "./shopify";
import { addProductToWoocommerceCart } from "./woocommerce";

const handleAddProductToCart: THandleAddProductToCart = (
  cart,
  cartUrl,
  type,
  customer
) => {
  switch (type) {
    case "Shopify":
      addProductToShopifyCart(cart, cartUrl);
      break;
    case "Woocommerce":
      addProductToWoocommerceCart(cart, cartUrl);
      break;
    case "Custom":
      if (customer === "gresillons") {
        addProductToGressillonsCart(cart, cartUrl);
      }
      break;
    default:
      break;
  }
};

const sendToExternalCart: TSendToExternalCart = (
  cart,
  cartUrl,
  type,
  customer
) => {
  handleAddProductToCart(cart, cartUrl, type, customer);
};

export default sendToExternalCart;
