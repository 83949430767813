import { TAddProductToWoocommerceCart } from "./interface";

export const addProductToWoocommerceCart: TAddProductToWoocommerceCart = async (
  cartUrl,
  id,
  quantity = 1
) => {
  const formData = {
    id,
    quantity,
  };

  try {
    const response = await fetch(`${cartUrl}/wp-json/wc/store/cart/add-item`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      return true;
    } else {
      throw new Error("Erreur lors de l'ajout au panier");
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};
